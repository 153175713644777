import { Directive, Input, ElementRef, type SimpleChanges, inject } from '@angular/core';

@Directive({
  selector: '[appBtnLoading]',
  standalone: true,
})
export class ButtonLoadingDirective {
  private readonly ref = inject(ElementRef);
  @Input('appBtnLoading') isLoading: boolean;

  private savedContent: any;
  private savedWidth: string;
  private timeout: any;
  private savedDisabledValue: string;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading) {
      const ref = this.ref.nativeElement as HTMLElement;
      if (changes.isLoading.currentValue) {
        ref.classList.add('disabled');
        this.savedDisabledValue = ref.getAttribute('disabled');
        ref.setAttribute('disabled', 'true');
        this.timeout = setTimeout(() => {
          this.savedContent = ref.innerHTML;
          this.savedWidth = ref.style.width;
          ref.style.width = `${ref.clientWidth}px`;
          ref.innerHTML = `<div class="btn-loader"></div>`;
        }, 500);
      } else {
        if (!this.timeout) {
          return;
        }

        clearTimeout(this.timeout);
        if (this.savedContent) {
          ref.innerHTML = this.savedContent;
          ref.style.width = this.savedWidth;
        }
        ref.classList.remove('disabled');
        if (this.savedDisabledValue) {
          ref.setAttribute('disabled', this.savedDisabledValue);
        } else {
          ref.removeAttribute('disabled');
        }
      }
    }
  }
}
